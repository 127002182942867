import React, { useState } from "react";
import AuthLayout from "./AuthLayout";
import t from "./texts";
import { useForm } from "react-hook-form";
import Cookies from "universal-cookie";

import EyeIcon from "./images/eye.svg";
import { useDispatch, useSelector } from "react-redux";
import { SignUpAction } from "../../actions/auth.actions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function SignUp(props) {
  const cookies = new Cookies();

  const history = useHistory();
  const dispatch = useDispatch();

  const { signUpLoading, signUpError } = useSelector((state) => {
    return {
      signUpLoading: state.privileges.signUpLoading,
      signUpError: state.privileges.signUpError,
      // user: state.privileges.user,
      // token: state.privileges.token,
    };
  });

  const user = cookies.get("user");
  const token = cookies.get("Rtoken");

  if (user && token) {
    history.push("/dashboard");
  }

  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  const minimum_password_length = 4;

  const form = useForm({
    defaultValues: {
      email: "",
      name: "",
      mobile: "",
      password: "",
      password_confirmation: "",
    },
  });

  const { register, handleSubmit, formState, watch } = form;

  const submitSuccess = (data, e) => {
    dispatch(SignUpAction(data));
  };
  const submitError = (errors, e) => {
    console.log("submit error:", errors);
  };

  return (
    <AuthLayout>
      <div>
        <h2>{t("devise.shared_links.sign_up")}</h2>
        <form onSubmit={handleSubmit(submitSuccess, submitError)}>
          <div className="mb-4">
            <div className="field !mb-0">
              <input
                type="email"
                name="email"
                placeholder={t("devise.registrations.email")}
                autoFocus={true}
                {...register("email", {
                  required: {
                    value: true,
                    message: t("errors.messages.email_required"),
                  },
                  maxLength: 128,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: t("errors.messages.email_validation"),
                  },
                })}
              />
            </div>
            {formState.errors.email && (
              <p className="text-xs text-red-600 mt-1">
                {formState.errors.email.message}
              </p>
            )}
          </div>

          <div className="mb-4">
            <div className="field !mb-0">
              <input
                type="text"
                name="name"
                placeholder={t("devise.registrations.name")}
                {...register("name", {
                  required: {
                    value: true,
                    message: t("errors.messages.name_required"),
                  },
                  maxLength: 64,
                })}
              />
            </div>
            {formState.errors.name && (
              <p className="text-xs text-red-600 mt-1">
                {formState.errors.name.message}
              </p>
            )}
          </div>

          <div className="mb-4">
            <div className="field !mb-0">
              <input
                type="text"
                name="mobile"
                placeholder={t("devise.registrations.mobile_number")}
                {...register("mobile", {
                  pattern: {
                    value: /^05[0-9]{8}$/,
                    message: t("errors.messages.mobile_validation"),
                  },
                })}
              />
            </div>
            {formState.errors.mobile && (
              <p className="text-xs text-red-600 mt-1">
                {formState.errors.mobile.message}
              </p>
            )}
          </div>

          <div className="mb-4">
            <div className="field !mb-0">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder={`${t(
                  "devise.registrations.password"
                )} (${minimum_password_length} ${t(
                  "devise.registrations.minimum_characters"
                )})`}
                maxLength={128}
                {...register("password", {
                  required: {
                    value: true,
                    message: t("errors.messages.password_required"),
                  },
                  minLength: {
                    value: minimum_password_length,
                    message: t("errors.messages.password_minimum_length", {
                      minimum_password_length,
                    }),
                  },
                })}
              />
              <span className="password-toggle" onClick={handlePasswordToggle}>
                <img src={EyeIcon} alt="eye-icon" className="eye-icon w-6" />
              </span>
            </div>
            {formState.errors.password && (
              <p className="text-xs text-red-600 mt-1">
                {formState.errors.password.message}
              </p>
            )}
          </div>

          <div className="mb-4">
            <div className="field !mb-0">
              <input
                type={showPassword ? "text" : "password"}
                name="password_confirmation"
                placeholder={t("devise.registrations.confirm_password")}
                maxLength={128}
                {...register("password_confirmation", {
                  required: {
                    value: true,
                    message: t(
                      "errors.messages.password_confirmation_required"
                    ),
                  },
                  minLength: {
                    value: minimum_password_length,
                    message: t("errors.messages.password_minimum_length", {
                      minimum_password_length,
                    }),
                  },
                  validate: (value) =>
                    value === watch("password") ||
                    t("errors.messages.password_confirmation_match"),
                })}
              />
              <span className="password-toggle" onClick={handlePasswordToggle}>
                <img src={EyeIcon} alt="eye-icon" className="eye-icon w-6" />
              </span>
            </div>
            {formState.errors.password_confirmation && (
              <p className="text-xs text-red-600 mt-1">
                {formState.errors.password_confirmation.message}
              </p>
            )}
          </div>

          <div className="actions">
            <button type="submit" disabled={signUpLoading}>
              {t("devise.shared_links.sign_up")}
            </button>
          </div>
        </form>

        <div className="">
          {t("devise.shared_links.already_have_account")}{" "}
          <a href="/#/signin" className="underline">
            {t("devise.shared_links.log_in")}
          </a>
        </div>
      </div>
    </AuthLayout>
  );
}

export default SignUp;
